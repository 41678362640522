.search-area {
  display: flex;
  justify-content: center;
  margin: 3vh auto;
  width: 90%;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: auto;
}

input[type="search"] {
  width: 60%;
  padding: 10px 35px 10px 15px;
  border: 1px solid gray;
  border-radius: 10vw;
  outline: none;
  transition: width 0.4s ease-in-out;
}

input[type="search"]:focus {
  width: 100%;
}

.search-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  margin-left: -33px;
}

.search-button img {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

@media (width > 600px) {
  .search-area {
    width: 60%;
  }
}
