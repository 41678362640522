.transparent-container {
    background-color: rgba(255, 255, 255, .5);
    width: 70%;
    margin: 20px auto;
    padding: 20px;
}

.about-text {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.headline {
    font-size: xx-large;
    font-weight: bold;
    margin: 3vh 0vw 5vh 0vw;
}

.medium-text {
    font-size: large;
}

.small-text {
    font-size: medium;
}