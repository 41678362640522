.card {
  /*border: 2px solid lightgray; */
  border-radius: 5px;
  /*padding: 0vh 1vw 1vh 1vw;*/
  width: 100%;
}

h1 {
  margin: 1vh 0vw;
}
