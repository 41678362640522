/* That blue: #2a59e9 */

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  flex-direction: column;
}

.bold {
  font-weight: bold;
}