.landing-body {
  text-align: center;
  margin-top: 5vh;
}

.landing-message {
  width: 70%;
  margin-bottom: 2rem;
}

.landing-navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
}

.image-button:hover {
  cursor: pointer;
}

.image-button {
  width: 60%;
  margin: 1rem;
  border-radius: 10px;
}

@media (min-width: 600px) {
  .landing-message {
    width: 50%;
  }

  .landing-navigation {
    flex-direction: row;
    justify-content: center;
  }

  .image-button {
    width: 30%;
  }
}
